import React from "react";
import { ProviderProps } from "./ContextTypes";
import { TaskUpdate } from "./Task";
import { apiFetch, eventEmitter, useAppContext } from ".";
import { useQuery } from "@tanstack/react-query";
import { shuffle } from "@/tools";

export type Content = { text: string } | { object: object };
export type MessageState = "ongoing" | "message";
export interface Chat {
    role: string;
    content: Content;
    state?: MessageState;
}

export interface ConsentTask {
    id: string;
    title: string;
    description: string;
    tags: string[];
    components: string[];
}

export interface ConsentTaskUpdateWrapper {
    task: ConsentTask;
    update: TaskUpdate | string;
}

export interface ConsentProject {
    id: string;
    title: string;
    description: string;
}

export type ChangeType = "created" | "updated" | "archived";
export type ConsentRequest = { task: ConsentTask } | { taskUpdate: ConsentTaskUpdateWrapper } | { project: ConsentProject };

export type ConsentTaskReply = { task: { taskId: string, changeType: ChangeType } };
export type ConsentProjectReply = { project: { projectId: string, changeType: ChangeType } };
export type ConsentReply = ConsentTaskReply | ConsentProjectReply;


export interface Consent {
    consentId: string;
    payload: ConsentRequest
}

export interface TipPayload {
    content: string;
    completeMessage: string;
    path?: string;
    icon?: string;
}

export type Message = Chat | Consent;

export interface InstructionContextProps {
    messages: Message[];
    tips: TipPayload[];
    addMessage: (message: Message) => void;
    clearMessages: () => void;
    sendDashboardMessage: (state: string) => void;
    onAcceptConsent: (index: number) => void;
    onRejectConsent: (index: number) => void;
    onAcceptAllConsent: () => void;
    onRejectAllConsent: () => void;

    conversationOpen: boolean;
    setConversationOpen: (open: boolean) => void;
}

export const InstructionContext = React.createContext<InstructionContextProps | undefined>(undefined);

async function getTips(): Promise<TipPayload[]> {
    const tips = await apiFetch<TipPayload[]>("/api/v1/tips", {
        method: "GET",
    });
    return shuffle(tips);
}

export const useTips = () => {
    return useQuery({ queryKey: ['tips'], queryFn: getTips });
}

const InstructionProvider: React.FC<ProviderProps> = ({ children }) => {
    const { sendWebMessage, socketIsConnected } = useAppContext();
    const [conversationOpen, setConversationOpen] = React.useState<boolean>(false);
    const [messages, setMessages] = React.useState<Message[]>([]);
    const [tips, setTips] = React.useState<TipPayload[]>([]);

    const updateLastMessage = React.useCallback((msg: Chat) => {
        // Iterate in reverse to find the last message that is ongoing
        // if the first message we find is marked as 'message', create a new message.
        let found = false;
        for (let i = messages.length - 1; i >= 0; i--) {
            const message = messages[i];
            if ("state" in message) {
                if (message.state === "ongoing" && "text" in message.content && "text" in msg.content) {
                    message.content.text = `${message.content.text}${msg.content.text}`;
                    setMessages([...messages]);
                } else {
                    setMessages((currentItems) => [...currentItems, msg]);
                }
                found = true;
                break;
            }
        }
        if (!found) {
            setMessages((currentItems) => [...currentItems, msg]);
        }
    }, [messages]);

    const replaceOngoingMessage = React.useCallback((msg: Chat) => {
        // Iterate in reverse to find the last message that is ongoing and replace it
        // if the first message we find is marked as 'message', create a new message.
        let found = false;
        for (let i = messages.length - 1; i >= 0; i--) {
            const message = messages[i];
            if ("state" in message) {
                if (message.state === "ongoing" && "text" in message.content && "text" in msg.content) {
                    messages[i] = msg;
                    setMessages([...messages]);
                } else {
                    setMessages((currentItems) => [...currentItems, msg]);
                }
                found = true;
                break;
            }
        }
        if (!found) {
            setMessages((currentItems) => [...currentItems, msg]);
        }
    }, [messages]);

    const addMessage = React.useCallback((msg: Message) => {
        if ("role" in msg && "system" === msg.role) {
            if ("text" in msg.content && msg.content.text === "REINIT") {
                setMessages(messages => messages.filter((message) => !("role" in message) || "dashboard" !== message.role));
                return;
            }
        }

        if ("state" in msg) {
            if (msg.state === "ongoing") {
                updateLastMessage(msg);
            } else {
                replaceOngoingMessage(msg);
            }
        } else {
            setMessages((currentItems) => [...currentItems, msg]);
        }
    }, [updateLastMessage, replaceOngoingMessage]);

    const clearMessages = React.useCallback(() => {
        setMessages([]);
    }, []);

    const sendDashboardMessage = React.useCallback((state: string) => {
        if (!socketIsConnected) {
            return;
        }
        sendWebMessage({ instruct: { dashboard: state } });
        setMessages(messages => messages.filter((message) => !("role" in message) || "dashboard" !== message.role));
    }, [sendWebMessage, socketIsConnected]);

    const sendConsentMessage = React.useCallback((consentId: string, consented: boolean) => {
        if (!socketIsConnected) {
            console.error("Websocket is not connected");
            return;
        }
        sendWebMessage({ instruct: { consent: { consentId, state: consented ? "accept" : "deny" } } });
    }, [sendWebMessage, socketIsConnected]);

    const onAcceptAllConsent = React.useCallback(() => {
        for (let i = 0; i < messages.length; i++) {
            const message = messages[i];
            if ("consentId" in message) {
                const consentId: string = message.consentId
                sendConsentMessage(consentId, true);
            }
        }
        const remainingMessages = messages.filter((message) => !("consentId" in message));
        setMessages(remainingMessages);
    }, [messages, sendConsentMessage]);

    const onRejectAllConsent = React.useCallback(() => {
        for (let i = 0; i < messages.length; i++) {
            const message = messages[i];
            if ("consentId" in message) {
                const consentId: string = message.consentId
                sendConsentMessage(consentId, false);
            }
        }
        const remainingMessages = messages.filter((message) => !("consentId" in message));
        setMessages(remainingMessages);
    }, [messages, sendConsentMessage]);

    const onAcceptConsent = React.useCallback((index: number) => {
        const message = messages[index];
        if ("consentId" in message) {
            const consentId: string = message.consentId
            sendConsentMessage(consentId, true);
        }
        setMessages((messages) => {
            console.log("Replacing message at index", index);
            return [
                ...messages.slice(0, index),
                ...messages.slice(index + 1),
            ];
        });
    }, [messages, sendConsentMessage]);

    const onRejectConsent = React.useCallback((index: number) => {
        const message = messages[index];
        if ("consentId" in message) {
            const consentId: string = message.consentId
            sendConsentMessage(consentId, false);
        }
        setMessages((messages) => {
            console.log("Replacing message at index", index);
            return [
                ...messages.slice(0, index),
                ...messages.slice(index + 1),
            ];
        });
    }, [messages, sendConsentMessage]);

    React.useEffect(() => {
        eventEmitter.on("INSTRUCT-MESSAGE", addMessage);
        eventEmitter.on("INSTRUCT-CONSENT-REQUEST", addMessage);
        eventEmitter.on("INSTRUCT-CLEAR", clearMessages);

        return () => {
            eventEmitter.off("INSTRUCT-MESSAGE", addMessage);
            eventEmitter.off("INSTRUCT-CONSENT-REQUEST", addMessage);
            eventEmitter.off("INSTRUCT-CLEAR", clearMessages);
        };
    }, [addMessage, clearMessages]);

    React.useEffect(() => {
        getTips().then(setTips);
    }, []);

    const value = {
        messages,
        tips,
        addMessage,
        getTips,
        clearMessages,
        sendDashboardMessage,
        conversationOpen,
        setConversationOpen,
        onAcceptConsent,
        onRejectConsent,
        onAcceptAllConsent,
        onRejectAllConsent,
    };
    return (<InstructionContext.Provider value={value}>
        {children}
    </InstructionContext.Provider>);
};

export default InstructionProvider;
