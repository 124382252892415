import { useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactTimeAgo from "react-time-ago";
import { FaTrashCan } from "react-icons/fa6";
import { FaEdit, FaBan } from "react-icons/fa";

import { PersonelleImage } from "@/components/Personelle";
import { UserData } from "@/contexts/User";
import { useAppContext } from "@/contexts";
import { Dialog, DialogClose, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";


export interface CommentData {
    id: string,
    author: UserData,
    content: string,
    createdAt: string,
    updatedAt: string,
}

interface CommentProps {
    comment: CommentData;
    editComment: (commentId: string, content: string) => void;
    deleteComment: (commentId: string) => void;
}

function Comment({ comment, editComment, deleteComment }: CommentProps) {
    const { loggedInUser } = useAppContext();
    const [isEditing, setIsEditing] = useState(false);
    const [editContent, setEditContent] = useState(comment.content);
    const date = new Date(comment.createdAt + "Z");
    const editedDate = new Date(comment.updatedAt + "Z");

    const cancelEdit = () => {
        setIsEditing(false);
        setEditContent(comment.content);
    }

    const submitEdit = () => {
        setIsEditing(false);
        editComment(comment.id, editContent);
    }

    return (<div className="flex w-full card-detail__comment">
        <div className="ml-4 mt-2 mr-2 flex-col">
            <PersonelleImage userData={comment.author} />
            <div className="flex mt-2">
                {loggedInUser?.id == comment.author.id && (<div>
                    {isEditing ? <FaBan className="mt-1" title="Cancel" onClick={cancelEdit} /> : <FaEdit className="mt-1" title="Edit" onClick={() => setIsEditing(true)} />}
                </div>)}
                {loggedInUser?.id == comment.author.id &&
                    <Dialog>
                        <DialogTrigger>
                            <FaTrashCan title="Delete" className="mt-1 ml-1 text-red-600 dark:text-hellotrope-400" />
                        </DialogTrigger>
                        <DialogContent>
                            <Label className="editable__label">Confirm Delete</Label>
                            <DialogClose className="flex">
                                <Button className="btn bg-red-600 dark:bg-hellotrope-400" onClick={() => deleteComment(comment.id)}>Yes</Button>
                                <Button className="btn">No</Button>
                            </DialogClose>
                        </DialogContent>
                    </Dialog>}
            </div>
        </div>
        <div className="flex-col mt-3 mb-4 mr-2 w-full">
            <div className="flex mt-2">
                <span className="ml-2">{comment.author.username}</span>
                <ReactTimeAgo className="ml-2 text-sm" date={date} />
                <div className="flex-grow" />
                {comment.createdAt !== comment.updatedAt && <div className="flex-shrink text-sm mr-2">Edited:<ReactTimeAgo className="ml-2" date={editedDate} /></div>}
            </div>
            {isEditing ? (<div className="mt-6 ml-2 text-left flex-col">
                <textarea autoFocus className="card-detail__comment--editing" value={editContent} onChange={(e) => setEditContent(e.target.value)} />
                <div className="flex text-center">
                    <div onClick={cancelEdit} className="card-detail__button ml-auto mr-2">
                        Cancel
                    </div>
                    <div onClick={submitEdit} className="card-detail__button">
                        Comment
                    </div>
                </div>
            </div>) :
                <ReactMarkdown className="mt-6 ml-2 text-left">{comment.content}</ReactMarkdown>}
        </div>
    </div>);
}
export default Comment;
