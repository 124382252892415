import React from 'react';
import { MdCelebration, MdEmail } from 'react-icons/md';
import { useNavigate, useSearchParams } from 'react-router-dom';

async function joinBeta(code: string, email: string, givenName: string, familyName: string) {
    const response = await fetch(`/api/v1/beta/code/${code}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email,
            givenName,
            familyName,
        }),
    });
    if (!response.ok) {
        const body = await response.text();
        throw new Error('Failed to join beta: ' + body);
    }
}

async function joinBetaWaitlist(email: string, givenName: string, familyName: string) {
    const response = await fetch(`/api/v1/beta/request`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email,
            givenName,
            familyName,
        }),
    });
    if (!response.ok) {
        const body = await response.text();
        throw new Error('Failed to join beta: ' + body);
    }
}

async function acceptBetaInvite(code: string) {
    const response = await fetch(`/api/v1/beta/code/${code}/accept`, {
        method: 'POST',
    });
    if (!response.ok) {
        const body = await response.text();
        throw new Error(body);
    }
}

export const BetaSignupPage = () => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const [inviteCode, setInviteCode] = React.useState<string | null>(code);
    const [error, setError] = React.useState<string | null>(null);
    const [complete, setComplete] = React.useState<boolean>(false);

    function onJoinBeta(event: React.FormEvent<HTMLFormElement>) {
        if (!inviteCode) {
            return;
        }

        event.preventDefault();
        const form = event.currentTarget;
        const formElements = form.elements;
        const email = (formElements[0] as HTMLInputElement).value;
        const givenName = (formElements[1] as HTMLInputElement).value;
        const familyName = (formElements[2] as HTMLInputElement).value;
        joinBeta(inviteCode, email, givenName, familyName)
            .then(() => {
                setComplete(true);
            })
            .catch((error) => {
                setInviteCode(null);
                setError(error.message);
            });
    }

    function onSetCode(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const form = event.currentTarget;
        const formElements = form.elements;
        const code = (formElements[0] as HTMLInputElement).value;
        setInviteCode(code);
    }

    function onRequestBetaAccess(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const form = event.currentTarget;
        const formElements = form.elements;
        const email = (formElements[0] as HTMLInputElement).value;
        const givenName = (formElements[1] as HTMLInputElement).value;
        const familyName = (formElements[2] as HTMLInputElement).value;

        if (!email || !givenName || !familyName) {
            setError('Please fill in all fields');
            return;
        }

        joinBetaWaitlist(email, givenName, familyName)
            .then(() => {
                setComplete(true);
            })
            .catch((error) => {
                setError(error.message);
            });
    }

    return (
        <div className="description__container mt-32 w-1/2">
            <h1>Join the beta</h1>
            {inviteCode && !complete && <>
                <p className="text-right mr-4 mt-4">Enter your details to finish joining the beta</p>
                <form onSubmit={onJoinBeta} className="flex-col flex mt-4">
                    <input required type="email" className="input w-96 mr-4 p-1 mt-2 m-auto text-black" placeholder="Email" />
                    <input required type="givenName" className="input w-96 mr-4 p-1 mt-2 m-auto text-black" placeholder="First Name" />
                    <input required type="familyname" className="input w-96 mr-4 p-1 mt-2 m-auto text-black" placeholder="Last Name" />
                    <button type="submit" className="btn w-24 flex mt-4 ml-auto mr-4"><MdCelebration classname="m-1" /> Join</button>
                </form>
            </>}
            {complete && inviteCode && <p className="mt-24">Thank you for joining the beta! Check your email for a link to finish signing in.</p>}
            {complete && !inviteCode && <p className="mt-24">Thank you for your interest! You'll be hearing from us shortly.</p>}
            {!inviteCode && !complete && <>
                <h2 className="text-left ml-4">Have an invite code?</h2>
                <p className="text-left ml-4">Enter it below to join the beta</p>
                <form onSubmit={onSetCode} className="flex mt-4">
                    <input name="code" required type="text" className="input w-96 p-1 ml-4 text-black" placeholder="Invite code" />
                    <button type="submit" className="btn w-24 flex ml-4 text-lg">Submit</button>
                </form>
                <br />
                {error && <div className="error">{error}</div>}
                <h2 className="text-right mr-4 mt-8">Don't have an invite code?</h2>
                <p className="text-right mr-4">Sign up for the waitlist</p>
                <form onSubmit={onRequestBetaAccess} className="flex-col flex mt-4">
                    <input required type="email" className="input w-96 mr-4 p-1 mt-2 m-auto text-black" placeholder="Email" />
                    <input required type="givenName" className="input w-96 mr-4 p-1 mt-2 m-auto text-black" placeholder="First Name" />
                    <input required type="familyname" className="input w-96 mr-4 p-1 mt-2 m-auto text-black" placeholder="Last Name" />
                    <button type="submit" className="btn w-24 flex mt-4 ml-auto mr-4"><MdEmail classname="m-1" /> Request Access</button>
                </form>
            </>}
        </div>
    );
}

export const BetaInvitePage = () => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const navigate = useNavigate();
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (!code) {
            return;
        }
        acceptBetaInvite(code).then(() => {
            navigate('/');
        })
            .catch((error) => {
                setError(error.message);
            });
    }, [code, navigate]);

    if (!code) {
        return (<div className="description__container">
            <div><h1>Error</h1><p>Invalid invite code (not present)</p></div>
        </div>);
    }

    return (<div className="description__container">
        {error && <div><h1>Error</h1><p>{error}</p></div>}
    </div>);
}
