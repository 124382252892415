import { useEffect, useState } from "react";
import { FaUsers, FaTasks, FaDatabase, } from "react-icons/fa";
import { FaMessage, FaDiagramProject, FaRegChartBar } from "react-icons/fa6";
import { TbReport } from "react-icons/tb";
import { Milestone } from "lucide-react";

import { useAppContext, useInstructionContext, useProjectsContext } from "@/contexts";
import subseqLogo from "@/assets/SUBSEQ-logo-long.png";
import subseqLogoMobile from "@/assets/SUBSEQ-logo-tight.png";
import { useNavigate } from "react-router-dom";
import { Separator } from "@/components/ui/separator";
import ProjectSelect from "@/components/ProjectSelect";
import { Label } from "@/components/ui/label";
import { ScrollArea } from "@/components/ui/scrollarea";
import { Project } from "@/contexts/Project";

interface SideBarProps {
    active: string;
}

function SideBar({ active }: SideBarProps) {
    const navigate = useNavigate();

    const { loggedInUser, mobile, narrow } = useAppContext();
    const { setConversationOpen, conversationOpen } = useInstructionContext();
    const { activeProject } = useProjectsContext();

    function openInstruction() {
        setConversationOpen(!conversationOpen);
        if (narrow) {
            if (!conversationOpen) {
                navigate("/app/chat");
            } else {
                navigate(-1);
            }
        }
    }

    function navigateHome() {
        navigate("/");
    }

    const [logo, setLogo] = useState(mobile ? subseqLogoMobile : subseqLogo);
    const [divClass, setDivClass] = useState(mobile ? "flex ml-auto" : "sidebar__full-div");
    const [logoClass, setLogoClass] = useState(mobile ? "h-12 w-12 m-0" : "h-12");
    const [logoImageClass, setLogoImageClass] = useState(mobile ?
        "sidebar__logo-img cursor-pointer" :
        "sidebar__logo-img cursor-pointer mt-4");

    useEffect(() => {
        setLogo(mobile ? subseqLogoMobile : subseqLogo);
        setDivClass(mobile ? "ml-2 flex w-full" : "sidebar__full-div");
        setLogoClass(mobile ? "h-12 w-16" : "h-12");
        setLogoImageClass(mobile ?
            "sidebar__logo-img cursor-pointer mt-1 ml-1 w-12 h-12" :
            "sidebar__logo-img cursor-pointer mt-4"
        );
    }, [mobile]);

    if (!loggedInUser) {
        return null;
    }

    const iconClass = mobile ? "mr-3 ml-3" : "mr-auto ml-2";

    return (
        <div className="sidebar">
            <div className={logoClass}>
                <img className={logoImageClass} onClick={navigateHome} src={logo} />
            </div>
            {mobile ? <div className={divClass}>
                <Icons
                    mobile={mobile}
                    narrow={narrow}
                    activeProject={activeProject}
                    active={active}
                    iconClass={iconClass}
                />
            </div> : <ScrollArea scrollHideDelay={0} className={divClass}>
                <Icons
                    mobile={mobile}
                    narrow={narrow}
                    activeProject={activeProject}
                    active={active}
                    iconClass={iconClass}
                />
            </ScrollArea>}
            <div className={mobile ? "" : "sidebar__lower"} >
                {!mobile && <Separator />}
                <SideBarIcon
                    name="instruct"
                    active={active}
                    icon={<FaMessage className={iconClass} size="24" />}
                    text="Project Assistant"
                    fn={openInstruction}
                    mobile={mobile}
                />
            </div>
        </div >
    );
}

interface IconsProps {
    mobile: boolean;
    narrow: boolean;
    activeProject: Project | null;
    active: string;
    iconClass: string;
}

const Icons = ({ mobile, narrow, activeProject, active, iconClass }: IconsProps) => {
    const navigate_ = useNavigate();
    const { conversationOpen, setConversationOpen } = useInstructionContext();

    function navigate(path: string) {
        if (narrow && conversationOpen) {
            setConversationOpen(false);
        }
        navigate_(path);
    }

    return <>{!mobile && <>
        {!mobile && <Separator />}
        <ProjectSelect activeProject={activeProject} />
        {!mobile && <Separator />}
    </>}
        <SideBarIcon
            name="tasks"
            active={active}
            icon={<FaTasks className={iconClass} size="24" />}
            text="Tasks"
            fn={() => navigate("/app/tasks")}
            mobile={mobile}
        />
        <SideBarIcon
            name="projects"
            active={active}
            icon={<FaDiagramProject className={iconClass} size="24" />}
            text="Projects"
            fn={() => navigate("/app/projects")}
            mobile={mobile}
        />
        <SideBarIcon
            name="users"
            active={active}
            icon={<FaUsers className={iconClass} size="24" />}
            text="Team"
            fn={() => navigate("/app/users")}
            mobile={mobile}
        />
        {!mobile && <Separator />}
        {!mobile && <Label className="ml-6">Coming Soon</Label>}
        <SideBarIcon
            name="charts"
            active={active}
            icon={<FaRegChartBar className={iconClass} size="24" />}
            text="Planning"
            fn={() => navigate("/app/charts")}
            mobile={mobile}
            disabled={true}
        />
        <SideBarIcon
            name="milestones"
            active={active}
            icon={<Milestone className={iconClass} size="24" />}
            text="Milestones"
            fn={() => navigate("/app/milestones")}
            mobile={mobile}
            disabled={true}
        />
        <SideBarIcon
            name="data"
            active={active}
            icon={<FaDatabase className={iconClass} size="24" />}
            text="Transcripts"
            fn={() => navigate("/app/data")}
            mobile={mobile}
            disabled={true}
        />
        <SideBarIcon
            name="reports"
            active={active}
            icon={<TbReport className={iconClass} size="24" />}
            text="Reports"
            fn={() => navigate("/app/reports")}
            mobile={mobile}
            disabled={true}
        /></>;
};


type VoidFunction = () => void;

interface SideBarIconProps {
    name: string;
    active: string;
    icon: React.ReactNode;
    fn?: VoidFunction;
    text?: string;
    mobile: boolean;
    disabled?: boolean;
}

const SideBarIcon = ({
    name,
    active,
    icon,
    fn = () => { },
    text = "tooltip ☁️",
    mobile,
    disabled
}: SideBarIconProps) => {
    if (disabled) {
        if (mobile) {
            return <> </>;
        }
        return (<div className="sidebar__icon--disabled">
            {icon}
            {<span className="m-2 text-sm">{text}</span>}
        </div>);
    }

    return (<div
        id={`${name}SidebarIcon`}
        onClick={fn}
        className={`${name === active ? "sidebar__icon--active" : "sidebar__icon"} group`}
    >
        {icon}
        {!mobile && <span className="m-2 text-sm">{text}</span>}
    </div >);
}

export default SideBar;
