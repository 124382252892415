import React from "react";
import { FaBell } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { hasUnreadNotifications } from "@/components/Notification";
import { eventEmitter, isDevelopment } from "@/contexts";
import { Button } from "@/components/ui/button";

const NotificationBell: React.FC = () => {
    const navigate = useNavigate();
    const [isNotificationActive, setIsNotificationActive] = React.useState(isDevelopment);
    const checkActiveNotifications = React.useCallback(() => {
        hasUnreadNotifications().then((value) => {
            setIsNotificationActive(value)
        });
    }, []);

    React.useEffect(() => {
        checkActiveNotifications();
        eventEmitter.on("NOTIFICATION", checkActiveNotifications);
        return () => {
            eventEmitter.off("NOTIFICATION", checkActiveNotifications);
        };
    }, [checkActiveNotifications]);

    const goToNotifications = () => {
        setIsNotificationActive(false);
        navigate("/app/notifications");
    }

    const className = isNotificationActive ? "notification__icon--active" : "notification__icon";
    return <Button className="bg-transparent mr-4"><FaBell className={className} onMouseDown={goToNotifications} /></Button>;
}
export default NotificationBell;
