import AppContextProvider from "../contexts/AppContext";
import NotificationPage from "./NotificationPage";
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

// Payments
import UpgradePage from "./UpgradePage";
import PurchasePage from "./PurchasePage";
import CheckoutPage from "./CheckoutPage";

// Organization data
import ChartWindow from "./cards/chart/ChartWindow";
import DataWindow from "./cards/data/DataWindow";
import MilestoneWindow from "./cards/milestone/MilestoneWindow";
import ProjectWindow from "./cards/project/ProjectWindow";
import TaskWindow from "./cards/task/TaskWindow";
import UserWindow from "./cards/user/UserWindow";
import UserSettings from "./cards/user/UserSettings";
import WorklogPage from "./WorklogPage";
import WorkflowPage from "./WorkflowPage";
import { InstructionsPage } from "./Instructions";

// Email links
import InvitePage from "./InvitePage";
import { BetaSignupPage, BetaInvitePage } from "./BetaInvitePage";
import VerifyPage from "./VerifyPage";

// Errors
import ServerDown from "./ServerDown";
import SomethingWentWrong from "./SomethingWentWrong";

// Onboarding
import OnboardingPage from "./OnboardingPage";

// Import apps
import GithubImportPage, { GithubImportLogin } from "./GithubImportPage";

// Admin pages
import Admin from "./Admin";
import Dashboard from "./Dashboard";

const router = createBrowserRouter([
    { path: "/", element: <Dashboard />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/charts/*", element: <ChartWindow />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/data/*", element: <DataWindow />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/milestones/*", element: <MilestoneWindow />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/down", element: <ServerDown />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/flows", element: <WorkflowPage /> },
    { path: "/app/notifications", element: <NotificationPage />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/projects/*", element: <ProjectWindow />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/settings", element: <UserSettings />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/tasks/*", element: <TaskWindow />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/users/*", element: <UserWindow />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/worklog", element: <WorklogPage />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/chat", element: <InstructionsPage />, "errorElement": <SomethingWentWrong /> },

    // Email links
    { path: "/app/invite", element: <InvitePage />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/beta-invite", element: <BetaInvitePage />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/verify-email", element: <VerifyPage />, "errorElement": <SomethingWentWrong /> },

    // Payments
    { path: "/app/upgrade", element: <UpgradePage />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/checkout/complete", element: <PurchasePage />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/checkout", element: <CheckoutPage />, "errorElement": <SomethingWentWrong /> },

    // Onboarding
    { path: "/app/onboarding", element: <OnboardingPage />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/beta", element: <BetaSignupPage />, "errorElement": <SomethingWentWrong /> },

    // Import apps
    { path: "/app/login/github", element: <GithubImportLogin />, "errorElement": <SomethingWentWrong /> },
    { path: "/app/import/github", element: <GithubImportPage />, "errorElement": <SomethingWentWrong /> },

    // Admin pages
    { path: "/app/admin", element: <Admin />, "errorElement": <SomethingWentWrong /> },
]);

function App() {
    return (
        <AppContextProvider>
            <RouterProvider router={router} />
        </AppContextProvider>
    );
}

export default App;
