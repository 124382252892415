import { FaArchive, FaPlus } from "react-icons/fa";
import { FixedSizeList } from "react-window";
import { useLocation, useNavigate } from "react-router-dom";
import InfiniteLoader from "react-window-infinite-loader";
import { useDrop } from "react-dnd";
import { Description } from "@radix-ui/react-dialog";
import { useState, useCallback, useEffect, useRef } from "react";

import UtilityCard from "@/components/cards/UtilityCard";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/cards/project/MiniProject";
import { CardData, isProjectData } from "@/components/cards/CardDataInterfaces";
import { Dialog, DialogClose, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { Project } from "@/contexts/Project";
import { useProjectsContext } from "@/contexts";

const BOTTOM_MARGIN = 198;

interface ProjectGridProps {
    projects: Project[];
    containerWidth: number;
    containerHeight: number;
    projectSource: string;
    dragging: Project | null;
    setDragging: (dragging: Project | null) => void;
    dropRule?: (card: Project) => void;
}

function ProjectGrid({ projects, containerWidth, containerHeight, projectSource, dragging, setDragging, dropRule }: ProjectGridProps) {
    const {
        deleteProject,
    } = useProjectsContext();
    const [deleteCardData, setDeleteCardData] = useState<Project | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const navigate = useNavigate();

    const handleUtilDrop = useCallback((card: CardData) => {
        if (isProjectData(card)) {
            setDeleteDialogOpen(true);
            setDeleteCardData(card);
        }
    }, []);

    const [, connectDrop] = useDrop({
        accept: "GRID_ITEM",
        drop(item: unknown) {
            const project = item as Project;
            if (dropRule && projects.findIndex((item) => item.id === project.id) === -1) {
                dropRule(project);
            }
        },
    });
    const ref = useRef<HTMLDivElement>(null);
    connectDrop(ref);

    const [utilIcon, setUtilIcon] = useState(<FaPlus className="ml-2 mr-2" />);
    const createText = "Create Project";
    const [utilText, setUtilText] = useState(createText);
    const location = useLocation();

    useEffect(() => {
        if (dragging) {
            setUtilIcon(<FaArchive className="text-rufous-600 dark:text-hellotrope-400 ml-2 mr-2" />);
            setUtilText("Archive Project");
        } else {
            setUtilIcon(<FaPlus className="ml-2 mr-2" />);
            setUtilText(createText);
        }
    }, [dragging]);

    const handleClick = useCallback((id: string) => {
        const project = projects.find((item) => item.id == id);

        const maybeId = location.pathname.split("/").pop();
        if (project && project.id !== maybeId) {
            navigate(project.id);
        }
    }, [projects, navigate, location]);

    const height = containerHeight - BOTTOM_MARGIN;
    const width = containerWidth;

    const handleUtilClick = useCallback(() => {
        navigate(`/app/projects/create?projectSource=${projectSource}`);
    }, [navigate, projectSource]);

    const itemData = {
        items: projects,
        dragging,
        handleClick,
        setDragging,
        dropRule: () => { }
    };

    const onDialogConfirmDelete = useCallback(() => {
        deleteProject(deleteCardData?.id as string);
    }, [deleteProject, deleteCardData]);

    return (
        <div className="flex-col">
            <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
                <DialogContent>
                    <DialogTitle>Project deletion</DialogTitle>
                    <Description>Are you sure you want to delete this project?<br />This cannot be undone</Description>
                    <DialogClose className="flex">
                        <Button className="editable__button bg-red-600 dark:bg-hellotrope-400 text-white dark:text-black" onClick={onDialogConfirmDelete}>Yes</Button>
                        <Button className="editable__button">No</Button>
                    </DialogClose>
                </DialogContent>
            </Dialog>
            <UtilityCard
                handleCardClick={handleUtilClick}
                handleCardDrop={handleUtilDrop}
                dragging={dragging}
                icon={utilIcon}
                cardText={utilText}
                style={{ height: 90, width: "calc(100% - 32px)" }}
            />
            <div ref={ref}>
                <InfiniteLoader
                    isItemLoaded={() => true}
                    itemCount={projects.length}
                    loadMoreItems={() => { }}
                >
                    {({ onItemsRendered, ref }) => (
                        <FixedSizeList
                            className="scroll-list"
                            height={height}
                            itemSize={100}
                            width={width}
                            itemData={itemData}
                            itemCount={projects.length}
                            onItemsRendered={onItemsRendered}
                            ref={ref} >
                            {Card}
                        </FixedSizeList>
                    )}
                </InfiniteLoader>
            </div>
        </div>
    );
}
export default ProjectGrid;
