import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyEmail, resendEmail } from '../contexts/User';
import { login, useAppContext } from '../contexts';

import { Button } from '@/components/ui/button';
import logo from "../assets/SUBSEQ-logo-long.png";
import Spinner from './Spinner';

const VerifyPage: React.FC = () => {
    const { loggedInUser, loginAttempted, setLoggedInUser } = useAppContext();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [verifyFailed, setVerifyFailed] = React.useState(false);
    const [verifySucceeded, setVerifySucceeded] = React.useState(false);
    const [resentEmail, setResentEmail] = React.useState(false);

    const doResendEmail = async () => {
        setResentEmail(true);
        await resendEmail();
    }

    const token = searchParams.get('id');

    React.useEffect(() => {
        const token = searchParams.get('id');
        if (token && loggedInUser) {
            if (loggedInUser.role === 'active') {
                setVerifySucceeded(true);
                return;
            }

            verifyEmail(token).then((data) => {
                if (data.message === 'verified') {
                    if (loggedInUser) {
                        setLoggedInUser({ ...loggedInUser, role: 'active' });
                    }
                    setVerifySucceeded(true);
                } else {
                    setVerifyFailed(true);
                }
            });
        }
    }, [navigate, searchParams, loggedInUser, setLoggedInUser]);

    if (!loginAttempted) {
        return <Spinner />;
    }

    if (!loggedInUser) {
        login();
        return null;
    }

    function handleLogoutClick() {
        window.location.replace("/auth/logout");
    }

    return <div className="h-dvh w-dvw text-center flex flex-col items-center">
        <img src={logo} alt="SubSeq" className="sidebar__logo-img h-20 m-20" />
        {!verifyFailed && token === null && <div className="flex-col items-center">
            <h1>Verify your email</h1>
            <div className="text-lg mt-4">Check your inbox for a welcome message</div>
        </div>}
        {verifyFailed && <div className="flex-col items-center">
            <h1>Email verification failed</h1>
            <div className="text-lg mt-4">Sorry that verification link is invalid or expired!</div>
        </div>}
        {verifySucceeded && <div className="flex-col items-center">
            <h1>Email verified!</h1>
            <div className="text-lg mt-4">Get ready to accelerate with SubSeq!</div>
            <div className="text-lg"><b>Let's dive in!</b></div>
            <button className="btn mt-10" onClick={() => navigate("/app/onboarding?step=0")}>Get Started</button>
        </div>}
        {!verifySucceeded && <>
            <button disabled={resentEmail} className="btn mt-10" onClick={doResendEmail}>{resentEmail ? "Email Resent" : "Resend Email"}</button>
            <div className="text-sm mt-4">Didn't get the email? Check your spam folder or <a href="mailto:support@subseq.io">contact us</a></div>
        </>}
        <Button className="btn fixed top-10 right-10" onClick={handleLogoutClick}>Log out</Button>
    </div>;
};
export default VerifyPage;
