import { createRef, useEffect, useState } from "react";

import AppPage from "../../AppPage";
import TaskGrid from "./TaskGrid";
import Swimlanes from "./Swimlanes";
import TaskDetail from "./TaskDetail";
import { Routes, Route, useParams } from 'react-router-dom'
import { TaskData } from "../../../contexts/Task";
import { useAppContext, useProjectsContext } from "../../../contexts";
import { useCallback } from "react";
import { DropTargetMonitor } from "react-dnd";
import TaskCreate from "./TaskCreate.tsx";

function Details() {
    const { mobile } = useAppContext();
    const { slug } = useParams();
    // Matches the styling of the TaskGrid in Swimlanes.tsx
    if (!slug) {
        return null;
    }
    return (
        <>
            {!mobile && <TaskScrollView />}
            <TaskDetail slug={slug} />
        </>
    );
}

function TaskScrollView() {
    const { projectSlug } = useParams();
    const { activeProject, setActiveProject, projects, orgProjects } = useProjectsContext();

    useEffect(() => {
        if (projectSlug && projectSlug !== activeProject?.slug) {
            const project = projects.find((project) => project.slug === projectSlug);
            if (project) {
                setActiveProject(project);
            } else {
                const orgProject = orgProjects.find((project) => project.slug === projectSlug);
                if (orgProject) {
                    setActiveProject(orgProject);
                }
            }
        }
    }, [projectSlug, activeProject, projects, orgProjects, setActiveProject]);

    const { mobile, width, height } = useAppContext();
    const dropRule = useCallback((card: TaskData) => {
        return (item: unknown, monitor: DropTargetMonitor) => {
            const cardItem = item as TaskData;
            if (monitor.isOver() && cardItem.id !== card.id) {
                console.log("Dropped", cardItem, "onto", card);
                // Swap is not implemented yet
            }
        };
    }, []);
    const [dragging, setDragging] = useState<TaskData | null>(null);

    const outerDivRef = createRef<HTMLDivElement>();
    const [containerWidth, setContainerWidth] = useState(width);
    const [containerHeight, setContainerHeight] = useState(height);

    useEffect(() => {
        if (outerDivRef.current) {
            const rect = outerDivRef.current.getBoundingClientRect();
            setContainerWidth(rect.width);
            setContainerHeight(rect.height);
        }
    }, [outerDivRef, width, height]);
    const outerDivClasses = mobile ? (
        "ml-1 mt-1 w-full"
    ) : ("ml-2 ml-2 w-1/3");

    return <div className={outerDivClasses} ref={outerDivRef}>
        <h2 className="text-center">Project:&nbsp;{activeProject?.name}</h2>
        <TaskGrid containerWidth={containerWidth} containerHeight={containerHeight} dragging={dragging} setDragging={setDragging} showUtilityCard={true} dropRule={dropRule} />
    </div>
}

function TaskWindow() {
    const { mobile } = useAppContext();
    return (
        <AppPage active="tasks">
            <Routes>
                <Route path="/" element={mobile ? <TaskScrollView /> : <Swimlanes />} />
                <Route path="create" element={<TaskCreate />} />
                <Route path=":projectSlug" element={mobile ? <TaskScrollView /> : <Swimlanes />} />
                <Route path="id/:slug" element={<Details />} />
            </Routes>
        </AppPage>
    );
}

export default TaskWindow;
