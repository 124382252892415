import { useEffect, useState } from "react";

import { cache } from "@/contexts";
import { UserData } from "@/contexts/User";
import unknownUserImage from "@/assets/unknown.jpg";
import { Label } from "@/components/ui/label";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

interface PersonelleImageProps {
    userData?: UserData;
    className?: string;
    onMouseDown?: () => void;
}

export function PersonelleImage({ userData, onMouseDown, className }: PersonelleImageProps) {
    const [userImage, setUserImage] = useState(unknownUserImage);
    useEffect(() => {
        if (userData) {
            if (userData.imageId) {
                setUserImage(userData.imageId);
            } else {
                cache.getPortrait(userData.id).then((image) => {
                    setUserImage(image);
                    userData.imageId = image;
                });
            }
        }
    }, [userData]);

    return <Avatar className="m-auto">
        <AvatarImage src={userImage} onMouseDown={onMouseDown} className={className} />
        <AvatarFallback>{userData?.username[0] || "U"}</AvatarFallback>
    </Avatar>;
}

interface PersonelleProps {
    userData?: UserData;
    tag: string;
    dropdown?: boolean;
    onMouseDown?: () => void;
    className?: string;
}

function Personelle({ userData, tag, dropdown, onMouseDown, className }: PersonelleProps) {
    if (!userData) {
        return null;
    }
    let tagClassString = "personelle__tag";
    if (className) {
        tagClassString += " " + className;
    }

    const label = tag ? <><Label>{tag}</Label><br /></> : <></>;

    return (
        <div
            onMouseDown={onMouseDown}
            className={dropdown ? "personelle__tag--drop" : tagClassString}
        >
            <PersonelleImage userData={userData} />
            <div className="personelle__text">
                {label}
                {userData.username}
            </div>
        </div>
    );
}

interface PersonelleListProps {
    userSelectionShortList: UserData[];
    handleUserSelect: (userId: string) => void;
}

export function PersonelleList({
    userSelectionShortList,
    handleUserSelect,
}: PersonelleListProps) {
    const listItems = userSelectionShortList.map((userData) => (
        <li>
            <Personelle
                key={userData.id}
                userData={userData}
                dropdown={true}
                tag="Select"
                onMouseDown={() => handleUserSelect(userData.id)}
            />
        </li>
    ));
    return <ul className="personelle__dropdown">{listItems}</ul>;
}

interface PersonelleSelectorProps {
    selectedUserData?: UserData;
    tag: string;
    className?: string;
    userSelectionShortList: UserData[];
    handleUserSelect: (userId: string) => void;
}

export function PersonelleSelector({
    selectedUserData,
    tag,
    className,
    userSelectionShortList,
    handleUserSelect,
}: PersonelleSelectorProps) {
    let topUser;
    if (selectedUserData === null || selectedUserData === undefined) {
        topUser = {
            id: "",
            created: "",
            username: "None",
            email: "",
            jobTitle: "",
        };
    } else {
        topUser = selectedUserData;
    }
    const [listOpen, setListOpen] = useState(false);

    function onClickTopUser() {
        setListOpen(!listOpen);
    }

    function onClickListUser(userId: string) {
        setListOpen(false);
        handleUserSelect(userId);
    }

    const personelleList = (
        <PersonelleList
            userSelectionShortList={userSelectionShortList}
            handleUserSelect={onClickListUser}
        />
    );
    const classes = "flex-col cursor-pointer" + (className ? " " + className : "");

    return (
        <div className={classes}>
            <Personelle userData={topUser} tag={tag} onMouseDown={onClickTopUser} />
            {listOpen && personelleList}
        </div>
    );
}

export default Personelle;
