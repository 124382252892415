import { useCallback, useEffect, useState } from "react";
import { FaUserMinus, FaUserPlus } from "react-icons/fa";
import { Description } from "@radix-ui/react-dialog";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList } from "react-window";
import { useNavigate } from "react-router-dom";

import MiniUser from "@/components/cards/user/MiniUser";
import UtilityCard from "@/components/cards/UtilityCard";
import { Button } from "@/components/ui/button";
import { CardData, isUserData } from "@/components/cards/CardDataInterfaces";
import { Dialog, DialogClose, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { UserData } from "@/contexts/User";
import { useUsersContext } from "@/contexts";

const BOTTOM_MARGIN = 148;

interface UserGridProps {
    containerWidth: number;
    containerHeight: number;
}

function UserGrid({ containerWidth, containerHeight }: UserGridProps) {
    const { users, indexesById, removeFromOrg } = useUsersContext();
    const navigate = useNavigate();

    const [utilIcon, setUtilIcon] = useState(<FaUserPlus className="ml-2 mr-2" />);
    const inviteText = "Invite User"
    const [utilText, setUtilText] = useState(inviteText);
    const [dragging, setDragging] = useState<UserData | null>(null);

    useEffect(() => {
        if (dragging) {
            setUtilIcon(<FaUserMinus className="text-rufous-600 dark:text-hellotrope-400 ml-2 mr-2" />);
            setUtilText("Remove from Team");
        } else {
            setUtilIcon(<FaUserPlus className="ml-2 mr-2" />);
            setUtilText(inviteText);
        }
    }, [dragging]);

    const handleClick = useCallback((id: string) => {
        const index = indexesById[id];
        const user = users[index];
        if (user) {
            navigate(`/app/users/${user.id}`);
        }
    }, [users, indexesById, navigate]);

    const height = containerHeight - BOTTOM_MARGIN;
    const width = containerWidth;

    const itemData = {
        items: users,
        dragging,
        handleClick,
        setDragging,
        dropRule: () => { }
    };

    const handleUtilClick = useCallback(() => {
        navigate("/app/users/invite");
    }, [navigate]);

    const [deleteCardData, setDeleteCardData] = useState<UserData | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const handleUtilDrop = useCallback((card: CardData) => {
        if (isUserData(card)) {
            setDeleteDialogOpen(true);
            setDeleteCardData(card);
        }
    }, []);

    const onDialogConfirmDelete = useCallback(() => {
        if (deleteCardData) {
            removeFromOrg(deleteCardData.id);
            setDeleteCardData(null);
        }
    }, [deleteCardData, removeFromOrg]);

    const children = (
        <div className="flex-col">
            <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
                <DialogContent>
                    <DialogTitle>Team member removal</DialogTitle>
                    <Description>Are you sure you want to remove this team member?<br />This cannot be undone</Description>
                    <DialogClose className="flex">
                        <Button className="editable__button bg-red-600 dark:bg-hellotrope-400 text-white dark:text-black" onClick={onDialogConfirmDelete}>Yes</Button>
                        <Button className="editable__button">No</Button>
                    </DialogClose>
                </DialogContent>
            </Dialog>
            <UtilityCard
                handleCardClick={handleUtilClick}
                handleCardDrop={handleUtilDrop}
                dragging={dragging}
                icon={utilIcon}
                cardText={utilText}
                style={{ height: 100, width: 'calc(100% - 32px)' }}
            />
            <InfiniteLoader
                isItemLoaded={() => true}
                itemCount={users.length}
                loadMoreItems={() => { }}
            >
                {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                        className="scroll-list"
                        height={height}
                        width={width}
                        itemSize={110}
                        itemData={itemData}
                        itemCount={users.length}
                        onItemsRendered={onItemsRendered}
                        ref={ref} >
                        {MiniUser}
                    </FixedSizeList>
                )}
            </InfiniteLoader>
        </div>
    );

    return children;
}
export default UserGrid;
