import React from "react";
import { useAppContext, useProjectsContext } from "@/contexts";
import { BsExclamationTriangleFill, BsFillCaretUpFill, BsFillCaretDownFill } from "react-icons/bs";
import { MdHorizontalRule } from "react-icons/md";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdownmenu";
import { InfoIcon } from "@/components/Floater";

interface PriorityProps {
    value: number;
    className?: string;
}

export function PriorityIcon({ value, className }: PriorityProps) {
    const { theme } = useAppContext();
    const criticalPriorityColor = theme === "dark" ? "#E879F9" : "#333333";
    const highPriorityColor = theme === "dark" ? "#7AE7C7" : "#333333";
    const medPriorityColor = theme === "dark" ? "#DDDDDD" : "#333333";
    const lowPriorityColor = theme === "dark" ? "#21295C" : "#333333";

    const style = {
        color: highPriorityColor,
        fontSize: "1.5rem",
    };
    let priorityIcon = <BsFillCaretUpFill style={style} className={className} />;
    // Critical: Less than 0
    // High: 0 to 9
    // Medium: 10 to 19
    // Low: 20+
    if (value < 0) {
        style.color = criticalPriorityColor;
        priorityIcon = <BsExclamationTriangleFill style={style} className={className} />;
    } else if (value >= 10 && value < 20) {
        style.color = medPriorityColor;
        priorityIcon = <MdHorizontalRule style={style} className={className} />;
    } else if (value >= 20) {
        style.color = lowPriorityColor;
        priorityIcon = <BsFillCaretDownFill style={style} className={className} />;
    }
    return priorityIcon;
}

interface PrioritySetterProps {
    value: number;
    onSetPriority(value: number): void;
}

export function priorityValuesToPriorityArray(priorityValues: Record<number, string>): [number, string][] {
    const priorities: [number, string][] = [];
    for (const key in priorityValues) {
        const keyInt = parseInt(key);
        const name = priorityValues[keyInt];
        priorities.push([keyInt, name]);
    }
    return priorities.sort((a, b) => a[0] - b[0]);
}

export function priorityNameFromPriorityValues(value: number, priorities: [number, string][]): string {
    let name = "Least";
    for (let i = 0; i < priorities.length; i++) {
        const [val, priName] = priorities[i];
        if (val >= value) {
            name = priName;
            break;
        }
    }
    return name;
}

function Priority({ value, onSetPriority }: PrioritySetterProps) {
    const { priorityValues } = useProjectsContext();
    const triggerRef = React.createRef<HTMLButtonElement>();

    if (!priorityValues) {
        return null;
    }

    const priorities = priorityValuesToPriorityArray(priorityValues);
    const name = priorityNameFromPriorityValues(value, priorities);

    const selectPriority = (value: number) => {
        onSetPriority(value);
    };

    return (
        <DropdownMenu>
            <DropdownMenuTrigger className="w-full" ref={triggerRef}>
                <div className="m-2 flex items-center justify-between rounded-lg border p-4">
                    <h5>Priority</h5>
                    <h2 className="priority-text"><PriorityIcon className="mr-1" value={value} />{name}</h2>
                    <InfoIcon
                        content="Priority controls the order tasks are scheduled. Urgent/critical will interrupt other work in the schedule."
                        className="text-sm omnibar__icon"
                    />
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="priority-dropdown panel">
                <div className="priority-dropdown-content">
                    {priorities.map(([key, value], index) => {
                        return (
                            <DropdownMenuItem key={key} onClick={() => selectPriority(key)} className="priority-dropdown-item">
                                <PriorityIcon value={key} />
                                <span className="ml-2">{value}</span>
                                {index !== priorities.length - 1 && <hr className="priority-divider" />}
                            </DropdownMenuItem>
                        );
                    })}
                </div>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
export default Priority;
