export function getFont(element: HTMLElement): string {
    const computedStyle = window.getComputedStyle(element);
    return computedStyle.font;
}

export function getTextWidth(text: string, font: string): number {
    // Create an off-screen canvas element
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
        throw new Error("Unable to get 2D context for canvas.");
    }

    // Set the context's font to the specified font style
    context.font = font;

    // Measure the width of the text
    const metrics = context.measureText(text);
    return metrics.width;
}

const uuidRegex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$');
export function testUuid(candidate: string): boolean {
    return uuidRegex.test(candidate);
}

export function shuffle<T>(array: T[]): T[] {
    const copy = [...array];
    for (let i = copy.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [copy[i], copy[j]] = [copy[j], copy[i]];
    }
    return copy;
}

export function selectFromWidth(titles: string[], widthSpace: number, font: string): string | undefined {
    let width;
    let usedTitle;

    for (let i = 0; i < titles.length; i++) {
        width = getTextWidth(titles[i], font);
        usedTitle = titles[i];
        if (width < widthSpace) {
            break;
        }
    }
    return usedTitle;
}
