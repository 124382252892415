import React from 'react';
import { useAppContext } from '../contexts';

type LEDProps = {
    status: 'green' | 'orange' | 'red';
    blink?: boolean;
};

const LedDisplay: React.FC<LEDProps> = ({ status, blink }) => {
    const { theme } = useAppContext();
    const getColor = (status: 'green' | 'orange' | 'red') => {
        switch (status) {
            case 'green':
                return theme === 'dark' ? "#7AE7C7" : "#00BB00";
            case 'orange':
                return theme === 'dark' ? "#FF7C00" : "#DD8F00";
            case 'red':
                return theme === 'dark' ? "#F24333" : "#EE0000";
            default:
                return '#000000';
        }
    };

    const style = {
        borderRadius: '50%',
        backgroundColor: getColor(status),
        boxShadow: `0 0 4px ${getColor(status)}`,
        border: '1px solid #333',
        animation: blink ? 'blink 1s infinite' : 'none'
    };

    return <div className="h-2 w-2 mt-2 ml-2 mr-2" style={style} />;
};

export default LedDisplay;
