import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { FilterRule, TaskData, useUpdateTask } from "../../../contexts/Task";
import { isDevelopment, useAppContext, useProjectsContext, apiFetch } from "../../../contexts";
import TaskGrid from "./TaskGrid";
import { useDrop, DropTargetMonitor } from "react-dnd";
import { Link, useParams } from "react-router-dom";

interface SwimlaneData {
    id: string;
    name: string;
    showUtilityCard: boolean;
    dropBehavior: "unassign" | "assignSelf" | "close";
    filterRule: FilterRule;
}

interface SwimlanesResult {
    swimlanes: SwimlaneData[];
    dragging: TaskData | null;
    setDragging: (task: TaskData | null) => void;
}

async function loadProjectSwimlanes(projectId: string): Promise<SwimlanesResult> {
    return await apiFetch(`/api/v1/project/${projectId}/swimlanes`, {});
}

interface SwimlaneProps {
    swimlane: SwimlaneData;
    dragging: TaskData | null;
    setDragging: (task: TaskData | null) => void;
    width: number;
    height: number;
}

function Swimlane({ swimlane, dragging, setDragging, width, height }: SwimlaneProps) {
    const ref = useRef<HTMLDivElement>(null);
    const { mutate: updateTask } = useUpdateTask();
    const { activeProject } = useProjectsContext();
    const projectId = activeProject?.id;
    const filter = swimlane.filterRule;

    const [, connectDrop] = useDrop({
        accept: "GRID_ITEM",
        drop(item: unknown, monitor: DropTargetMonitor) {
            const task = item as TaskData;
            if (monitor.isOver()) {
                if (projectId) {
                    updateTask({ projectId, taskId: task.id, data: swimlane.dropBehavior });
                }
            }
        },
    });
    connectDrop(ref);

    const dropRule = useCallback(() => {
        return (item: unknown, monitor: DropTargetMonitor) => {
            const cardItem = item as TaskData;
            if (monitor.isOver()) {
                if (projectId) {
                    updateTask({ projectId, taskId: cardItem.id, data: swimlane.dropBehavior });
                }
            }
        };
    }, [projectId, swimlane.dropBehavior, updateTask]);
    const swimlaneName = transformToCamel(swimlane.name) + "Swimlane";

    return (<div id={swimlaneName} ref={ref} className="p-1 m-2 border-neutral-700 dark:border-neutral-200 border-dashed rounded-md border-2">
        <h2 className="text-center">{swimlane.name}</h2>
        <TaskGrid
            containerWidth={width}
            containerHeight={height}
            dragging={dragging}
            setDragging={setDragging}
            filterRule={filter}
            showUtilityCard={swimlane.showUtilityCard}
            dropRule={dropRule}
            hasTaskIcon={false}
        />
    </div>);
}

function transformToCamel(name: string): string {
    let newName = name.replace(/\s/g, "");
    newName = newName.charAt(0).toLowerCase() + newName.slice(1);
    return newName;
}

function Swimlanes() {
    const { projectSlug } = useParams();
    const [swimlanes, setSwimlanes] = useState<SwimlaneData[]>([]);
    const { activeProject, setActiveProject, projects, orgProjects } = useProjectsContext();
    const { loggedInUser, width, height, mobile } = useAppContext();

    useEffect(() => {
        if (projectSlug && projectSlug !== activeProject?.slug) {
            const project = projects.find((project) => project.slug === projectSlug);
            if (project) {
                setActiveProject(project);
            } else {
                const orgProject = orgProjects.find((project) => project.slug === projectSlug);
                if (orgProject) {
                    setActiveProject(orgProject);
                }
            }
        }
    }, [projectSlug, activeProject, projects, orgProjects, setActiveProject]);

    useEffect(() => {
        if (isDevelopment) {
            setSwimlanes([
                {
                    id: "1",
                    name: "Backlog",
                    showUtilityCard: true,
                    dropBehavior: "unassign",
                    filterRule: "open"
                },
                {
                    id: "2",
                    name: "In Progress",
                    showUtilityCard: false,
                    dropBehavior: "assignSelf",
                    filterRule: "inProgress"
                },
                {
                    id: "3",
                    name: "Closed",
                    showUtilityCard: false,
                    dropBehavior: "close",
                    filterRule: "closed"
                },
            ]);
        }
        if (activeProject?.id === undefined) {
            return
        }
        loadProjectSwimlanes(activeProject.id).then((result: SwimlanesResult) => {
            setSwimlanes(result.swimlanes);
        });
    }, [activeProject?.id]);

    const [dragging, setDragging] = useState<TaskData | null>(null);
    if (swimlanes?.length === 0) {
        if (loggedInUser !== null && activeProject === null) {
            return (<div className="description__container">
                <h1 className="m-10">You need to create a project first!</h1>
                <p>After creating a project you can start adding tasks to it.</p>
                <br />
                <Link to="/app/projects/create?projectSource=organization">Create your first project</Link>
            </div>);
        } else {
            return null;
        }
    }

    const adjustedWidth = mobile ? width : width - 128 - 84;
    const adjustedHeight = mobile ? height - 112 : height - 80;

    return (
        <Suspense fallback={<h1 className="m-10">Loading...</h1>}>
            {swimlanes.map((swimlane) => (
                <Swimlane dragging={dragging} setDragging={setDragging} key={swimlane.id} swimlane={swimlane} width={adjustedWidth / swimlanes.length} height={adjustedHeight} />
            ))}
        </Suspense>
    );
}
export default Swimlanes;
