/** @type {import('tailwindcss').Config} */
import animate from 'tailwindcss-animate';
import { DarkModeConfig } from 'tailwindcss/types/config';

export default {
    mode: "jit",
    darkMode: 'class' as Partial<DarkModeConfig>,
    content: ["./index.html", "./src/**/*.{js,ts,jsx,tsx}"],
    theme: {
        extend: {
            fontSize: {
                sm: ['12px', '20px'] as [string, string],
                base: ['16px', '24px'] as [string, string],
                lg: ['20px', '28px'] as [string, string],
                xl: ['24px', '32px'] as [string, string]
            },
            colors: {
                cerulean: {
                    '300': '#8AB8D0',
                    '600': '#4281A4',
                    '800': '#295166'
                },
                verdigris: {
                    '300': '#8DCECC',
                    '500': '#48A9A6',
                    '700': '#37817E'
                },
                vanilla: {
                    '100': '#FBF8DA',
                    '300': '#F5EE9E'
                },
                rufous: {
                    '400': '#EA340B',
                    '600': '#A72608'
                },
                spacecadet: {
                    '700': '#21295C'
                },
                ultraviolet: {
                    '400': '#6046AA',
                    '600': '#54428E'
                },
                hellotrope: {
                    '400': '#E879F9'
                },
                night: {
                    '600': '#404040',
                    '900': '#151515'
                },
                aquamarine: {
                    '400': '#7AE7C7'
                },
                vermillion: {
                    '700': '#F24333'
                },
                background: 'hsl(var(--background))',
                foreground: 'hsl(var(--foreground))',
                card: {
                    DEFAULT: 'hsl(var(--card))',
                    foreground: 'hsl(var(--card-foreground))'
                },
                popover: {
                    DEFAULT: 'hsl(var(--popover))',
                    foreground: 'hsl(var(--popover-foreground))'
                },
                primary: {
                    DEFAULT: 'hsl(var(--primary))',
                    foreground: 'hsl(var(--primary-foreground))'
                },
                secondary: {
                    DEFAULT: 'hsl(var(--secondary))',
                    foreground: 'hsl(var(--secondary-foreground))'
                },
                muted: {
                    DEFAULT: 'hsl(var(--muted))',
                    foreground: 'hsl(var(--muted-foreground))'
                },
                accent: {
                    DEFAULT: 'hsl(var(--accent))',
                    foreground: 'hsl(var(--accent-foreground))'
                },
                destructive: {
                    DEFAULT: 'hsl(var(--destructive))',
                    foreground: 'hsl(var(--destructive-foreground))'
                },
                border: 'hsl(var(--border))',
                input: 'hsl(var(--input))',
                ring: 'hsl(var(--ring))',
                chart: {
                    '1': 'hsl(var(--chart-1))',
                    '2': 'hsl(var(--chart-2))',
                    '3': 'hsl(var(--chart-3))',
                    '4': 'hsl(var(--chart-4))',
                    '5': 'hsl(var(--chart-5))'
                }
            },
            borderRadius: {
                lg: 'var(--radius)',
                md: 'calc(var(--radius) - 2px)',
                sm: 'calc(var(--radius) - 4px)'
            }
        }
    },
    plugins: [animate],
};
