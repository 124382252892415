import { FaArrowRightLong, FaCheck } from 'react-icons/fa6';
import { Label } from '@/components/ui/label';

interface StepDotProps {
    step: number;
    label: string;
    currentStep: number;
    setStep?: (step: number) => void;
}

export function StepDot({ step, label, currentStep, setStep }: StepDotProps) {
    const buttonDisabled = setStep === undefined;
    const onClick = buttonDisabled ? () => { } : () => setStep(step);
    const dotInactiveClass = buttonDisabled ? "links__dot--inactive" : "links__dot--inactive cursor-pointer";
    const dotCompletedClass = buttonDisabled ? "links__dot p-1" : "links__dot cursor-pointer p-1";

    const dot = <div>
        <Label className="mb-1">{label}</Label>
        <div aria-label={`Step ${step}`} className="links__dot">{step + 1}</div>
    </div>
    const dotCompleted = <div>
        <Label className="mb-1">{label}</Label>
        <FaCheck aria-label={`Step ${step}`} onClick={onClick} className={dotCompletedClass} />
    </div>
    const dotInactive = <div>
        <Label className="mb-1">{label}</Label>
        <div aria-label={`Step ${step}`} onClick={onClick} className={dotInactiveClass}>{step + 1}</div>
    </div>
    if (step == currentStep) {
        return dot;
    } else if (step < currentStep) {
        return dotCompleted;
    } else {
        return dotInactive;
    }
}

interface StepsProps {
    steps: string[];
    currentStep: number;
    setStep?: (step: number) => void;
}

function Steps({ steps, currentStep, setStep }: StepsProps) {
    const stepsDisplay = [];
    for (let i = 0; i < steps.length; i += 1) {
        if (i != steps.length - 1) {
            stepsDisplay.push(<StepDot key={i} step={i} label={steps[i]} currentStep={currentStep} setStep={setStep} />);
            stepsDisplay.push(<FaArrowRightLong key={`arrow-${i}`} className="links__arrow" />);
        } else {
            stepsDisplay.push(<StepDot key={i} step={i} label={steps[i]} currentStep={currentStep} setStep={setStep} />);
        }
    }

    return <div className="flex mb-4">
        {stepsDisplay}
    </div>
}

export default Steps;
