import { FaXmark, FaDiagramProject } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import React from "react";
import { FaChartBar } from "react-icons/fa";
import Select, { ActionMeta, MultiValue } from "react-select";

import { eventEmitter, useAppContext, useChartContext, useProjectsContext } from "@/contexts";
import reactSelectTheme from "@/components/SelectTheme";
import { DetailControl } from "@/components/cards/DetailGrid";
import Checkbox from "@/components/ui/checkbox";

interface Option {
    readonly value: string;
    readonly label: string;
}

interface WeekCheckBoxesProps {
    week: boolean[];
    setWeek: (week: boolean[]) => void;
}

const WeekCheckBoxes = ({ week, setWeek }: WeekCheckBoxesProps) => {
    const setDay = (day: number) => {
        week[day] = !week[day];
        week = [...week];
        setWeek(week);
    }

    return (
        <div className="week-grid m-2 pb-2 mb-10">
            <label htmlFor="Sunday">Sunday</label>
            <label htmlFor="Monday">Monday</label>
            <label htmlFor="Tuesday">Tuesday</label>
            <label htmlFor="Wednesday">Wednesday</label>
            <label htmlFor="Thursday">Thursday</label>
            <label htmlFor="Friday">Friday</label>
            <label htmlFor="Saturday">Saturday</label>
            <Checkbox className="m-auto" id="Sunday" checked={week[0]} onCheckedChange={() => { setDay(0) }} />
            <Checkbox className="m-auto" id="Monday" checked={week[1]} onCheckedChange={() => { setDay(1) }} />
            <Checkbox className="m-auto" id="Tuesday" checked={week[2]} onCheckedChange={() => { setDay(2) }} />
            <Checkbox className="m-auto" id="Wednesday" checked={week[3]} onCheckedChange={() => { setDay(3) }} />
            <Checkbox className="m-auto" id="Thursday" checked={week[4]} onCheckedChange={() => { setDay(4) }} />
            <Checkbox className="m-auto" id="Friday" checked={week[5]} onCheckedChange={() => { setDay(5) }} />
            <Checkbox className="m-auto" id="Saturday" checked={week[6]} onCheckedChange={() => { setDay(6) }} />
        </div>
    );
}

const ChartCreate = () => {
    const { createChart } = useChartContext();
    const navigate = useNavigate();
    const vanish = () => {
        navigate(-1);
    };
    const { orgProjects } = useProjectsContext();
    const { theme, config } = useAppContext();
    const projectOptions = orgProjects.map((project) => ({ value: project.id, label: project.name }));
    const [selectedOptions, setSelectedOptions] = React.useState(projectOptions);
    const { selectTheme, classes } = React.useMemo(() => {
        return reactSelectTheme(theme, config);
    }, [theme, config]);
    const [week, setWeek] = React.useState([false, true, true, true, true, true, false]);

    const onChangeSelected = (_option: MultiValue<Option> | null, actionMeta: ActionMeta<Option>) => {
        if (actionMeta.action == "select-option" && actionMeta.option) {
            setSelectedOptions([...selectedOptions, actionMeta.option]);
        } else if (actionMeta.action == "remove-value") {
            setSelectedOptions(selectedOptions.filter((option) => option.value !== actionMeta.removedValue?.value));
        } else if (actionMeta.action == "clear") {
            setSelectedOptions([]);
        }
    };

    const createPlan = () => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        const create = {
            projectIds: selectedOptions.map((option) => option.value),
            startDate: date.toISOString(),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            workingDays: week,
        };
        createChart(create).then((newPlan) => {
            navigate("/app/charts/" + newPlan);
        }).catch((error) => {
            console.error("Failed to create plan", error);
            eventEmitter.emit("ERROR", "Failed to create plan, you may not have appropriate permissions.");
        });
    };

    const disabled = selectedOptions.length == 0;
    const buttonClass = disabled ? "disabled" : "bg-cerulean-300 shadow-sm shadow-black hover:text-white dark:hover:text-black dark:shadow-neutral-600 dark:bg-neutral-950 rounded-md";

    return (
        <div className="card-detail" >
            <div className="card-detail__top-bar">
                <div className="card__icon">
                    <FaDiagramProject />
                </div>
                <div className="card-detail__header-container">
                    <div className="flex-grow" />
                    <h2 className="card-detail__header">Project Planning</h2>
                    <div className="flex-grow" />
                </div>
                <div className="card-detail__close" onClick={vanish}>
                    <FaXmark />
                </div>
            </div>
            <div className="m-4 ml-6 text-left w-min">
                <div className="border p-2 mb-4 rounded-md">
                    <h2>Select the projects you want to create a plan for</h2>
                    {orgProjects.length > 0 ? <Select
                        className="w-max ml-0"
                        isMulti
                        value={selectedOptions}
                        isClearable
                        classNames={classes as object}
                        options={projectOptions}
                        theme={selectTheme}
                        onChange={onChangeSelected} /> : <p className="error">You don't have any team projects to create a plan for.</p>}
                </div>
                <div className="border p-2 mb-4 rounded-md">
                    <h2 className="mt-4 mb-4">What are your team's working days?</h2>
                    <WeekCheckBoxes week={week} setWeek={setWeek} />
                </div>
                <DetailControl disabled={disabled} className={buttonClass} icon={<FaChartBar />} text="Create plan" onClick={createPlan} />
            </div>
        </div>
    );
}
export default ChartCreate;
