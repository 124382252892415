import { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import Spinner from '@/components/Spinner';
import { apiFetch, isDevelopment } from '@/contexts';
import { Button } from '@/components/ui/button';
import { FaChevronLeft } from 'react-icons/fa6';

function ListItem({ text }: { text: string }) {
    return (
        <li className="flex w-full justify-center">
            <div className="flex-grow" />
            <FaCheck className="mr-4 text-cerulean-600" />
            <div className="w-44 text-right">{text}</div>
            <div className="flex-grow" />
        </li>
    );
}

interface Plan {
    name: string;
    tagline: string;
    price?: number;
    priceAnnual?: number;
    currency?: string;
    features: string[];
}

interface Pricing {
    starter: Plan;
    pro: Plan;
    enterprise: Plan;
}

function currencyToSymbol(price: number | undefined, currency: string | undefined): string {
    if (!price || !currency) {
        return '';
    }

    switch (currency.toUpperCase()) {
        case 'USD':
            return `$${price.toFixed(0)}`;
        case 'EUR':
            return `${price.toFixed(0)}€`;
        case 'GBP':
            return `£${price.toFixed(0)}`;
        default:
            return `${price} ${currency}`;
    }
}

async function fetchPricing(): Promise<Pricing> {
    return await apiFetch(`/api/v1/org/pricing`, {
        method: 'GET',
    });
}

function developmentTestData(): Pricing {
    return {
        starter: {
            name: 'Starter plan',
            tagline: 'For individuals and small teams',
            features: [
                'Task management',
                'Basic AI tools',
                'Small team size (3)',
                'Limited projects (3)',
            ],
        },
        pro: {
            name: 'Pro plan',
            tagline: 'For growing teams',
            price: 16,
            priceAnnual: 160,
            currency: 'USD',
            features: [
                'All Basic features',
                'Project planning view',
                'Project architect',
                'Full access to AI tools',
                'Speech to text',
                'Unlimited team size',
                'Unlimited projects',
                'Support contact',
            ],
        },
        enterprise: {
            name: 'Enterprise plan',
            tagline: 'For large teams and organizations',
            features: [
                'All Pro features',
                'Development support',
                'White glove support',
            ],
        },
    };
}

interface UpgradeProps {
    pricing: Pricing;
    period: string;
}

function UpgradePageWide({ pricing, period }: UpgradeProps) {
    const navigate = useNavigate();
    const proPrice = period === 'month' ? pricing.pro.price : pricing.pro.priceAnnual;

    return <>
        <div className="price-grid__left">
            <div className="flex-grow" />
            <h2 className='text-4xl m-2'>{pricing.starter.name}</h2>
            <p className='text-xl m-2'>{pricing.starter.tagline}</p>
            <ul className="m-10 justify-center object-center">
                {pricing.starter.features.map((feature, index) => (
                    <ListItem key={index} text={feature} />
                ))}
            </ul>
            <div className="flex-grow" />
            <p className='text-2xl m-4 font-light'>Free</p>
        </div>
        <div className="price-grid__center">
            <h2 className='text-4xl m-2'>{pricing.pro.name}</h2>
            <p className='text-xl m-2'>{pricing.pro.tagline}</p>
            <div className="flex-grow" />
            <ul className="m-10">
                {pricing.pro.features.map((feature, index) => (
                    <ListItem key={index} text={feature} />
                ))}
            </ul>
            <h2 className='text-6xl font-bold'>{currencyToSymbol(proPrice, pricing.pro.currency)}</h2>
            <span className='text-xl'>per user per {period}</span>
            <button className="btn btn-primary m-4 text-lg" onClick={() => navigate(`/app/checkout?plan=pro_${period}`)}>Upgrade</button>
        </div>
        <div className="price-grid__right">
            <div className="flex-grow" />
            <h2 className='text-4xl m-2'>{pricing.enterprise.name}</h2>
            <p className='text-xl m-2'>{pricing.enterprise.tagline}</p>
            <ul className="m-10">
                {pricing.enterprise.features.map((feature, index) => (
                    <ListItem key={index} text={feature} />
                ))}
            </ul>
            <div className="flex-grow" />
            <a href="https://subseq.io/contact"><button className="btn btn-primary m-4 text-lg">Contact us</button></a>
        </div>
    </>;
}

function UpgradePage() {
    const [pricing, setPricing] = useState<Pricing | null>(null);
    const [period, setPeriod] = useState<string>("month");

    useEffect(() => {
        if (isDevelopment) {
            setPricing(developmentTestData());
            return;
        }

        fetchPricing().then((data) => {
            setPricing(data);
        });
    }, []);

    if (!pricing) {
        return <div className="h-full w-full text-white"><Spinner /></div>;
    }

    const onClickSlider = () => {
        if (period === 'month') {
            setPeriod('year');
        } else {
            setPeriod('month');
        }
    }
    const sliderClass = (active: boolean): string => active ? 'price-grid__slider--selected' : 'flex-grow flex-shrink-0';

    return (<div className='p-4 m-4'>
        <div className="price-grid">
            <div className="price-grid__header">
                <div className="grid grid-cols-3">
                    <Link to="/" className="m-4 text-lg">
                        <Button className="border"><FaChevronLeft />Back to app</Button>
                    </Link>
                    <div>
                        <h1 className='text-4xl'>Upgrade your account</h1>
                    </div>
                </div>
                <p className='text-xl'>Maximize your productivity!</p>
                <div className="price-grid__slider_region" onClick={onClickSlider}>
                    <button className={sliderClass(period === "month")}>Monthly</button>
                    <button className={sliderClass(period === "year")}>Annual</button>
                </div>
            </div>
            <UpgradePageWide pricing={pricing} period={period} />
            <div className="m-auto text-center text-sm">
                Features may change. Prices do not include taxes. Additional terms apply.
            </div>
        </div>
    </div>);
}
export default UpgradePage;
