import React, { forwardRef, useImperativeHandle, useState } from "react";
import { eventEmitter, useAppContext, useInstructionContext } from "../contexts";

import { CommandDialog, CommandInput, CommandList, CommandEmpty, CommandGroup, CommandItem } from "./ui/command";
import { FaMagnifyingGlass, FaMessage } from "react-icons/fa6";
import { Dialog, DialogContent, DialogDescription, DialogTitle } from "./ui/dialog";
import Hidden from "./ui/hidden";
import { useLocation } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

function SearchControl({ metaKey }: { metaKey: "meta" | "ctrl" }) {
    const [menuOpen, setMenuOpen] = useState(false);
    const { setConversationOpen } = useInstructionContext();
    const searchRef = React.useRef<SearchModalHandle>(null);
    const location = useLocation();

    const openAssistant = () => {
        setConversationOpen(true);
        setMenuOpen(false);
    };

    const openSearch = () => {
        searchRef.current?.open();
        setMenuOpen(false);
    }

    const handleKeydown = React.useCallback((event: KeyboardEvent) => {
        let keyPressed = false;
        if (metaKey === "meta") {
            if (event.metaKey && event.key === 'k') {
                event.preventDefault();
                keyPressed = true;
            }
        } else {
            if (event.ctrlKey && event.key === 'k') {
                event.preventDefault();
                keyPressed = true;
            }
        }

        if (keyPressed) {
            setMenuOpen((open) => !open)
            searchRef.current?.close();
        }
    }, [metaKey]);

    React.useEffect(() => {
        document.addEventListener('keydown', handleKeydown);
        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, [handleKeydown]);

    const isTaskPage = location.pathname.includes("/tasks/id");
    const editTitle = () => {
        eventEmitter.emit("EDIT-TITLE");
        setMenuOpen(false);
    }

    return (<>
        <CommandDialog open={menuOpen} onOpenChange={setMenuOpen}>
            <CommandInput placeholder="Type a command or search..." />
            <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup heading="Suggestions">
                    <CommandItem onSelect={openAssistant}><FaMessage className="mr-2" />Assistant</CommandItem>
                    <CommandItem onSelect={openSearch}><FaMagnifyingGlass className="mr-2" />Search</CommandItem>
                </CommandGroup>
                {isTaskPage && <CommandGroup heading="Task Actions">
                    <CommandItem onSelect={editTitle}><FaEdit className="mr-2" />Edit Title</CommandItem>
                </CommandGroup>}
            </CommandList>
        </CommandDialog>
        <SearchModal ref={searchRef} />
    </>);
}

interface SearchBarProps {
    searchString: string;
    setSearchString: (s: string) => void;
    onBlur: () => void;
}

function SearchBar({ searchString, setSearchString, onBlur }: SearchBarProps) {
    function handleSearchUpdate(e: React.ChangeEvent<HTMLInputElement>) {
        const term = e.target.value;
        setSearchString(term);
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Escape") {
            setSearchString("");
        }
        if (e.key === "Enter") {
            e.preventDefault();
            e.currentTarget.blur();
            onBlur();
        }
    }

    return (
        <input
            type="text"
            className="omnibar__input"
            value={searchString}
            onChange={handleSearchUpdate}
            onKeyDown={handleKeyDown}
            placeholder="Search..."
        ></input>
    );
}

interface SearchModalHandle {
    open: () => void;
    close: () => void;
}

const SearchModal = forwardRef<SearchModalHandle, object>((_, ref) => {
    const { filter, setFilter } = useAppContext();
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        open: () => setOpen(true),
        close: () => setOpen(false),
    }));

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="border-none bg-transparent shadow-none">
                <Hidden><DialogTitle>Search</DialogTitle></Hidden>
                <div className="omnibar__input-container">
                    <SearchBar searchString={filter} setSearchString={setFilter} onBlur={() => setOpen(false)} />
                    <FaMagnifyingGlass className="omnibar__icon" onClick={() => setOpen(!open)} />
                </div>
                <DialogDescription className="mx-auto"><span>Esc will clear search</span></DialogDescription>
            </DialogContent>
        </Dialog>
    );
});

export { SearchBar, SearchControl };
