import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import Spinner from "./Spinner";
import { apiFetch, isDevelopment } from '../contexts';
import { useNavigate, useSearchParams } from 'react-router-dom';

const stripe_pub_key: string | undefined = import.meta.env.VITE_STRIPE_PUB_KEY;
const stripeKey = stripe_pub_key || 'pk_test_51Na7FfEUTzhfjnt2tIx5HKSus0FPtUrrIlcKnweMPGLqNWT52kx1wellWd7Zg6DbTMSwh9w6Jx77Q1FuklQxAqMU00hqFursMW';
const stripePromise = loadStripe(stripeKey);

interface Checkout {
    clientSecret: string;
}

async function fetchCheckout(plan: string) {
    return await apiFetch<Checkout>(`/api/v1/org/checkout?plan=${plan}`, {
        method: 'POST',
    });
}

const CheckoutPage: React.FC = () => {
    const [checkout, setCheckout] = useState<Checkout | null>(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (isDevelopment) {
            navigate('/app/checkout/complete');
            return;
        }
        const plan = searchParams.get('plan');
        if (plan) {
            fetchCheckout(plan).then((data) => {
                setCheckout(data);
            });
        }
    }, [navigate, searchParams]);

    if (!checkout) {
        return <div className="flex-col h-dvh w-dvw text-white">
            <div className="flex-grow" />
            <Spinner />
            <div className="flex-grow" />
        </div>;
    }
    return (
        <div id="checkout">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={checkout}>
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    );
};

export default CheckoutPage;
