import { createContext, useEffect, useState, useCallback } from 'react';
import { ProviderProps } from "./ContextTypes";
import { apiFetch, apiFetchNoContent, eventEmitter } from '.';
import { UserData } from './User';
import { Project } from './Project';

export interface Window {
    start: number;
    end: number;
}
export interface RepeatedBound {
    "n": number;
    "bound": Window;
}
export type Bound = { "lower": number } | { "upper": number };
export type Constraint = { "hardConstraint": Bound } | { "repeatedBlock": RepeatedBound } | { "block": Window };

export interface EventSegment {
    start: number;
    duration: number;
}

export interface ChartTask {
    taskId: string;
    slug: string;
    titleSummaries: string[];
    state: string;
    priority: number;
    attention: number;
    row: number;
    assignee?: UserData;
    expectedStart: string;
    actualStart?: string;

    expectedDuration: number;
    actualDuration?: number;

    constraints: Constraint[];
    dependencies: string[];
    children: string[];
    dueDate: string;
    segments: EventSegment[];
}

export interface ChartProblem {
    eventId: string;
    problem: string;
}

export interface ChartPlan {
    planId: string;
    completed: boolean;
    completedOn: string;
    error?: string;
    seed: string;
    tasks: ChartTask[];
    workers: { workers: Record<string, Constraint[]>, usernames: Record<string, string> };
    problems: ChartProblem[];
    utilization: Record<string, number>;
}

export interface Utilization {
    workerId: string;
    utilizationRate: number;
}

export interface ChartPlanMetadata {
    projectIds: string[];
    startDate: string;
    timeZone: string;
    workingDays: boolean[];
    problems: ChartProblem[];
    utilization: Utilization[];
}

export interface Chart {
    planId: string;
    orgId: string;
    createdAt: string;
    createdBy: UserData;
    projects: Project[];
    metadata: ChartPlanMetadata;
    processingFinishedAt?: string;
}

export interface CreateChart {
    projectIds: string[];
    startDate: string;
    timeZone: string;
    workingDays: boolean[];
}

interface NewPlan {
    planId: string;
}

async function createChart(create: CreateChart): Promise<NewPlan> {
    return await apiFetch("/api/v1/plan", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(create),
    });
}

async function getCharts(): Promise<Chart[]> {
    const result = await apiFetch<Chart[]>("/api/v1/plan/list", { method: "GET" });
    return result;
}

async function getChart(planId: string): Promise<ChartPlan> {
    return await apiFetch(`/api/v1/plan/${planId}`, { method: "GET" });
}

async function deleteChart(planId: string): Promise<boolean> {
    try {
        await apiFetchNoContent(`/api/v1/plan/${planId}`, { method: "DELETE" });
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export type ChartTaskUpdate = { "expected": { "start": string, "end": string } } |
{ "segments": { "segments": EventSegment[], "today": string, "units": string } } |
{ "assignee": string };

async function updateChartTask(planId: string, taskId: string, update: ChartTaskUpdate) {
    await apiFetchNoContent(`/api/v1/plan/${planId}/task/${taskId}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(update),
    });
}

export interface ChartContextProps {
    charts: Chart[];
    createChart: (create: CreateChart) => Promise<string>;
    deleteChart: (planId: string) => Promise<void>;
    getChart: (planId: string) => Promise<ChartPlan>;
    updateChartTask: (planId: string, taskId: string, update: ChartTaskUpdate) => Promise<void>;
}

export const ChartContext = createContext<ChartContextProps | undefined>(undefined);

const ChartProvider: React.FC<ProviderProps> = ({ children }) => {
    const [charts, setCharts] = useState<Chart[]>([]);

    useEffect(() => {
        getCharts().then(setCharts).catch(() => { });
    }, []);

    const createChartCb = useCallback(async (create: CreateChart) => {
        const newPlan = await createChart(create);
        const charts = await getCharts();
        setCharts(charts);
        return newPlan.planId;
    }, []);

    const deleteChartCb = useCallback(async (planId: string) => {
        if (await deleteChart(planId)) {
            const charts = await getCharts();
            setCharts(charts);
        } else {
            eventEmitter.emit("ERROR", "Failed to delete chart");
        }
    }, []);

    const getChartCb = useCallback(async (planId: string) => {
        return await getChart(planId);
    }, []);

    const updateChartTaskCb = useCallback(async (planId: string, taskId: string, update: ChartTaskUpdate) => {
        await updateChartTask(planId, taskId, update)
    }, []);

    const value = {
        charts,
        createChart: createChartCb,
        getChart: getChartCb,
        deleteChart: deleteChartCb,
        updateChartTask: updateChartTaskCb,
    };

    return (<ChartContext.Provider value={value}>
        {children}
    </ChartContext.Provider>);
}
export default ChartProvider;
