import { createRef, useCallback, useEffect, useState } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList } from 'react-window';
import { FaArchive, FaPlus } from 'react-icons/fa';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Description } from '@radix-ui/react-dialog';

import { Card } from '@/components/cards/chart/MiniChart';
import AppPage from '@/components/AppPage';
import { useAppContext, useChartContext } from '@/contexts';
import UtilityCard from "@/components/cards/UtilityCard";
import GanttChart from '@/components/cards/chart/GanttChart'
import ChartCreate from '@/components/cards/chart/ChartCreate';
import { Dialog, DialogClose, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { Chart } from '@/contexts/Chart';
import { Button } from '@/components/ui/button';
import { CardData } from '@/components/cards/CardDataInterfaces';

const BOTTOM_MARGIN = 148;

const ChartPage: React.FC = () => {
    const { width, height } = useAppContext();
    const [contentHeight, setHeight] = useState(height);
    const [contentWidth, setWidth] = useState(width);

    const outerDivRef = createRef<HTMLDivElement>();
    useEffect(() => {
        if (outerDivRef.current) {
            const rect = outerDivRef.current.getBoundingClientRect();
            setHeight(rect.height - BOTTOM_MARGIN);
            setWidth(rect.width);
        }
    }, [outerDivRef, width, height]);

    const navigate = useNavigate();
    const { charts, deleteChart } = useChartContext();

    const handleUtilClick = useCallback(() => {
        navigate("/app/charts/create");
    }, [navigate]);

    const createText = "Create Plan"
    const [utilIcon, setUtilIcon] = useState(<FaPlus className="ml-2 mr-2" />);
    const [utilText, setUtilText] = useState(createText);
    const [dragging, setDragging] = useState<Chart | null>(null);

    useEffect(() => {
        if (dragging) {
            setUtilIcon(<FaArchive className="text-rufous-600 dark:text-hellotrope-400 ml-2 mr-2" />);
            setUtilText("Remove Milestone");
        } else {
            setUtilIcon(<FaPlus className="ml-2 mr-2" />);
            setUtilText(createText);
        }
    }, [dragging]);

    const isItemLoaded = useCallback((index: number): boolean => {
        return index < charts.length;
    }, [charts.length]);

    const [deleteCardData, setDeleteCardData] = useState<Chart | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const handleUtilDrop = useCallback((card: CardData) => {
        const chart = card as Chart;
        setDeleteDialogOpen(true);
        setDeleteCardData(chart);
    }, []);

    const onDialogConfirmDelete = useCallback(() => {
        if (deleteCardData) {
            deleteChart(deleteCardData.planId);
            setDeleteCardData(null);
        }
    }, [deleteCardData, deleteChart]);

    const itemData = {
        items: charts,
        dragging,
        setDragging,
        handleClick: (id: string) => navigate(`/app/charts/${id}`),
        dropRule: () => { }
    };

    return <div className="flex-col max-w-lg" ref={outerDivRef}>
        <h1 className="ml-4">Plans</h1>
        <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
            <DialogContent>
                <DialogTitle>Plan deletion</DialogTitle>
                <Description>Are you sure you want to remove this plan?<br />This cannot be undone</Description>
                <DialogClose className="flex">
                    <Button className="editable__button bg-red-600 dark:bg-hellotrope-400 text-white dark:text-black" onClick={onDialogConfirmDelete}>Yes</Button>
                    <Button className="editable__button">No</Button>
                </DialogClose>
            </DialogContent>
        </Dialog>
        <UtilityCard
            handleCardClick={handleUtilClick}
            handleCardDrop={handleUtilDrop}
            dragging={dragging}
            icon={utilIcon}
            cardText={utilText}
            style={{ height: 100, width: 'calc(100% - 32px)' }}
        />
        <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={charts.length}
            loadMoreItems={() => { }}
        >
            {({ onItemsRendered, ref }) => (
                <FixedSizeList
                    className="scroll-list"
                    height={contentHeight}
                    width={contentWidth}
                    itemSize={110}
                    itemData={itemData}
                    itemCount={charts.length}
                    onItemsRendered={onItemsRendered}
                    ref={ref}>
                    {Card}
                </FixedSizeList>
            )}
        </InfiniteLoader>
    </div>
};

function ChartWindow() {
    return (
        <AppPage active="charts">
            <Routes>
                <Route path="/" element={<ChartPage />} />
                <Route path="create" element={<ChartCreate />} />
                <Route path=":id" element={<GanttChart />} />
            </Routes>
        </AppPage>
    );
}

export default ChartWindow;
