import { useContext } from 'react';
import { json } from "react-router-dom";
import { AppContext, AppContextProps } from './AppContext';
import { DataContext, DataContextProps } from './Data';
import { MilestoneContext, MilestoneContextProps } from './Milestone';
import { InstructionContext, InstructionContextProps } from './InstructionContext';
import { UsersContext, UsersContextProps } from './User';
import { LOGGED_OUT } from './ContextTypes';
import PortraitCache from "./Portrait";
import EventEmitter from "eventemitter3";
import { ProjectsContext, ProjectsContextProps } from './Project.js';
import { ChartContext, ChartContextProps } from './Chart.js';

export const isDevelopment = import.meta.env.VITE_IS_DEV;
export const eventEmitter = new EventEmitter();
export const cache = new PortraitCache();

export function login() {
    const location = window.location.pathname;
    window.location.replace(`/auth/login?origin=${encodeURI(location)}`);
}

export async function apiFetchNoContent(url: string, details: object, handler?: string | ((response: Response) => void)): Promise<void> {
    const response = await fetch(url, details);
    if (!response.ok) {
        if (response.status === 401) {
            eventEmitter.emit("LOGGED_OUT");
            throw json({ message: LOGGED_OUT }, { status: 401 });
        }
        if (handler) {
            if (typeof handler === "string") {
                eventEmitter.emit("ERROR", handler);
            } else {
                handler(response);
            }
        }
        throw json({ message: `Failed calling ${url}` }, { status: response.status });
    }
}

export async function apiFetch<T>(url: string, details: object, handler?: string | ((response: Response) => void)): Promise<T> {
    const response = await fetch(url, details);
    if (!response.ok) {
        if (response.status === 401) {
            eventEmitter.emit("LOGGED_OUT");
            throw json({ message: LOGGED_OUT }, { status: 401 });
        }
        if (handler) {
            if (typeof handler === "string") {
                eventEmitter.emit("ERROR", handler);
            } else {
                handler(response);
            }
        }
        throw json({ message: `Failed calling ${url}` }, { status: response.status });
    }
    return await response.json();
}

export const handleLogout = (otherwise?: (err?: unknown) => void) => {
    return (err: unknown) => {
        if (err === LOGGED_OUT) {
            eventEmitter.emit("LOGGED_OUT");
        } else if (otherwise) {
            otherwise(err)
        }
    }
}

export function useDataContext(): DataContextProps {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error("useDataContext must be used within a DataProvider");
    }
    return context
}

export function useAppContext(): AppContextProps {
    const context = useContext(AppContext);
    if (context === null) {
        throw new Error('useAppContext must be used within an AppContextProvider');
    }
    return context;
}

export function useInstructionContext(): InstructionContextProps {
    const context = useContext(InstructionContext);
    if (context === undefined) {
        throw new Error("useInstructionContext must be used within an InstructionProvider");
    }
    return context;
}

export function useUsersContext(): UsersContextProps {
    const context = useContext(UsersContext);
    if (context === undefined) {
        throw new Error("useUsersContext must be used within a UsersProvider");
    }
    return context;
}

export function useProjectsContext(): ProjectsContextProps {
    const context = useContext(ProjectsContext);
    if (context === undefined) {
        throw new Error("useTasksContext must be used within a TasksProvider");
    }
    return context;
}

export function useChartContext(): ChartContextProps {
    const context = useContext(ChartContext);
    if (context === undefined) {
        throw new Error("useChartContext must be used within a ChartProvider");
    }
    return context;
}

export function useMilestoneContext(): MilestoneContextProps {
    const context = useContext(MilestoneContext);
    if (context === undefined) {
        throw new Error("useMilestoneContext must be used within a MilestoneProvider");
    }
    return context
}
