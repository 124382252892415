import { useState } from "react";
import { apiFetch, eventEmitter } from "../../../contexts";
import Comment, { CommentData } from "./Comment";
import { updateTaskMutation, TaskUpdate } from "@/contexts/Task";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

async function fetchComments(taskId: string): Promise<CommentData[]> {
    return await apiFetch(`/api/v1/task/${taskId}/comments`, { method: "GET", });
}

interface TaskCommentsProps {
    taskId: string
}

function TaskComments({ taskId }: TaskCommentsProps) {
    const queryClient = useQueryClient();
    const { data: comments, isLoading, isError } = useQuery({
        queryKey: ['comments', taskId],
        queryFn: () => fetchComments(taskId)
    });
    const {mutate: updateComment} = useMutation({
        mutationFn: async ({ taskId, data }: { taskId: string, data: TaskUpdate }) => updateTaskMutation({ taskId, data }),
        onError: () => {
            eventEmitter.emit("ERROR", "Failed to update task");
        },
        onSettled: (_data, _error, variables) => {
            const { taskId } = variables;
            queryClient.invalidateQueries({ queryKey: ['comments', taskId] });
        }
    });

    const [content, setContent] = useState<string>("");
    const [isEditing, setIsEditing] = useState<boolean>(false);

    function changeCommentText(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target !== null) {
            const change = event.target.value;
            setContent(change);
        }
    }

    function clearComment() {
        setIsEditing(false);
        setContent("");
    }

    function submitComment() {
        if (content) {
            updateComment({ taskId, data: { addComment: { content } } });
        }
        setContent("");
    }

    function editComment(commentId: string, content: string) {
        updateComment({ taskId, data: { editComment: { commentId, content } } });
    }

    function deleteComment(commentId: string) {
        updateComment({ taskId, data: { removeComment: { commentId } } });
    }

    if (isError) {
        return <div>Error loading comments</div>;
    }

    if (isLoading || !comments) {
        return <div>Loading...</div>;
    }

    return (
        <div id="taskComments" className="card-detail__comments">
            <h2 className="card-detail__comments-header">Comments</h2>
            <div className="card-detail__comments-add">
                {isEditing ? <textarea autoFocus className="card-detail__comment--editing" value={content} onChange={(e) => setContent(e.target.value)} /> : (
                    <div className="instructions__input-box dark:bg-neutral-800">
                        <input
                            onChange={changeCommentText}
                            onFocus={() => setIsEditing(true)}
                            type="text"
                            className="instructions__input"
                            placeholder="Add a comment"
                        ></input>
                    </div>)}
                {isEditing && <div className="flex">
                    <div onClick={clearComment} className="card-detail__button ml-auto mr-2">
                        Cancel
                    </div>
                    <div onClick={submitComment} className="card-detail__button">
                        Comment
                    </div>
                </div>}
            </div>
            {comments.map((comment) => (
                <Comment key={comment.id} comment={comment} editComment={editComment} deleteComment={deleteComment} />
            ))}
        </div>);
}

export default TaskComments;
