import { eventEmitter } from ".";
import defaultImage from "../assets/unknown.jpg";
import { LOGGED_OUT } from "./ContextTypes";

class PortraitCache {
    portraits: Record<string, string>;

    constructor() {
        this.portraits = {};
    }

    setPortrait(userId: string, url: string) {
        this.portraits[userId] = url;
    }

    async getPortrait(userId: string): Promise<string> {
        let url = this.portraits[userId];
        if (url) {
            return url;
        }

        try {
            if (!userId) {
                return defaultImage;
            }

            const response = await fetch(`/api/v1/portrait/${userId}`, {
                method: "GET",
            });

            if (!response.ok) {
                if (response.status == 401) {
                    eventEmitter.emit("LOGGED_OUT");
                    throw LOGGED_OUT;
                }

                throw new Error("No such portrait");
            }

            const blob = await response.blob();
            url = URL.createObjectURL(blob);
            this.portraits[userId] = url;
        } catch (error) {
            url = defaultImage;
        }
        return url;
    }
}
export default PortraitCache;
